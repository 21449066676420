export const ZhChsContactStrings = {
  NAME: "名称",
  EMAIL: "电邮",
  DEPARTMENT: "部门",
  TITLE: "职位名称",
  PUBLIC_MESSAGE: "公开讯息",
  MOBILE: "手提电话号码",
  E_PROFILE: "E-Profile",

  DELETED_POSTFIX: "(已删除)",

  INACTIVE_USER_WARNING: "你所选取的用户，尚未激活即时通讯应用程式的帐号。 该用户启用帐户后，将收到所有未读的讯息。是否继续将他加入群组？",

  UNREGISTERED: "未激活",
  UNREGISTERED_FIELD: "此用户未启用",

  ME: '您'
};
