export const EnContactStrings = {
  NAME: "Name",
  EMAIL: "Email",
  DEPARTMENT: "Department",
  TITLE: "Title",
  PUBLIC_MESSAGE: "Public Message",
  MOBILE: "Mobile",
  E_PROFILE: "E-Profile",

  DELETED_POSTFIX: "(Deleted)",

  INACTIVE_USER_WARNING: "The user you selected has not yet activated an account for the instant messaging application. Once this user activates their account, they will receive all unread messages. Do you want to continue adding him to the group?",

  UNREGISTERED: "Unregistered",
  UNREGISTERED_FIELD: "This user is not registered",

  ME: 'You'
};
