import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { Chat } from '../../../models/chat';
import { UserContact } from '../../../models/user-contact';

import { UserContactService } from '../../services/data/user-contact/user-contact.service';
import { ChatService } from '../../services/data/chat/chat.service';
import { AccountManagerService } from '../../services/account/account-manager.service';

import * as _ from 'lodash';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { DataManagerService } from '../../services/data/data-manager.service';
import { ImageEditorService } from '../../../utilities/image-editor/image-editor.service';
import { TnNotificationService } from '../../../utilities/tn-notification/tn-notification.service';
import { TeamnoteConfigService } from '../../../configs/teamnote-config.service';
import { TeamNoteGeneralConstant } from '../../../constants/general.constant';
import { FtsContactService } from '../../../utilities/fts/contact/fts-contact.service';
import { FtsChatService } from '../../../utilities/fts/chat/fts-chat.service';
import { ModuleManagerService } from '../../services/module/module-manager.service';
import { ModuleKeyDefinition } from '../../../constants/module.constant';
import { ChatConstant } from '../../../constants/chat.constant';
import { FileManagerService } from '../../../utilities/file-manager/file-manager.service';
import { BroadcastGroup } from '../../broadcast/models/broadcast';
import { BroadcastService } from '../../broadcast/broadcast.service';
import { WebclientService } from '../../webclient.service';

@Component({
  selector: 'tn-chat-group-new',
  templateUrl: './chat-group-new.component.html',
  styleUrls: ['./chat-group-new.component.scss']
})
export class ChatGroupNewComponent implements OnInit {
  file: File;
  imgSrc: string = 'assets/images/general/default_group.png';
  groupName: string = '';
  groupNameMax: number;
  picAttachmentId: string = '';
  groupMessage: string = '';

  selectedMembers: UserContact[] = [];
  contactUsers: UserContact[] = [];

  chatGroups: Chat[] = [];
  filteredChatGroups: Chat[] = [];

  // Broadcast group
  IS_ALLOW_BROADCAST: boolean = false;
  broadcastGroups: BroadcastGroup[] = [];

  selectionMode: string = 'user';

  // isSelectFromUser: boolean = null;
  searchKeyword: string = '';
  
  isAllowChatSecurity: boolean = false;
  chatSecurityOptions: any[] = [];
  chatSecurityLevel: number = 0;

  isEnableWarnNoDeviceUserGroupChat: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<ChatGroupNewComponent>,
    private _userContactService: UserContactService,
    private _chatService: ChatService,
    private _fileManagerService: FileManagerService,
    private _accountManagerService: AccountManagerService,
    private _dataManagerService: DataManagerService,
    private _imageEditorService: ImageEditorService,
    private _tnNotificationService: TnNotificationService,
    private _teamnoteConfigService: TeamnoteConfigService,
    private _ftsContactService: FtsContactService,
    private _ftsChatService: FtsChatService,
    private _moduleManagerService: ModuleManagerService,
    private _broadcastService: BroadcastService,
    private _webClientService: WebclientService,
  ) { 
    this._teamnoteConfigService.config$.subscribe((config) => {
      this.groupNameMax = config.GENERAL.CHAT_GROUP_NAME_MAX_LENGTH;
    });
  }

  ngOnInit() {
    this.getAllContactUsers();
    this.getAllChatGroups();
    this.updateUnselectedList(this.searchKeyword);
    this.prepareChatSecurity();
    this.IS_ALLOW_BROADCAST = this._moduleManagerService.checkIfModuleExists(ModuleKeyDefinition.BROADCAST);
    this.isEnableWarnNoDeviceUserGroupChat = this._webClientService.warn_no_device_user_group_chat;

  }

  prepareChatSecurity(): void {
    this.isAllowChatSecurity = this._moduleManagerService.checkIfModuleExists(ModuleKeyDefinition.CHAT_SECURITY);
    this.chatSecurityOptions = [];
    let lowest = this._accountManagerService.fullLoginResponse.lowest_security_level;
    let highest = this._accountManagerService.fullLoginResponse.highest_security_level;

    if (lowest == null || lowest == undefined) {
      lowest = ChatConstant.SECURITY_LEVEL.NORMAL;
    }
    if (highest == null || highest == undefined) {
      highest = ChatConstant.SECURITY_LEVEL.RESTRICTED;
    }

    // generate a array to hold all possible security level availables from lowest to highest
    let level_available_arr = Array.from(new Array(highest + 1).keys()).slice(lowest)

    // using security_levels to get security levels available:
    let security_levels = this._accountManagerService.fullLoginResponse.security_levels

    // if security_levels exist, get the level available range of security_levels array
    if (typeof security_levels != "undefined" && security_levels.length > 0) {
      level_available_arr = security_levels  
    } 
    
    _.each(ChatConstant.SECURITY_LEVEL, (level) => {
      if (level_available_arr.includes(level)) {
        this.chatSecurityOptions.push({
          labelTranslateKey: "WEBCLIENT.CHATROOM.CLASSIFICATION_LEVEL.LEVEL" + level,
          value: level
        })
      }
    })
    this.chatSecurityLevel = level_available_arr[0] || lowest;
  }

  getAllContactUsers(): void {
    this.contactUsers = this._dataManagerService.getAllContactUsersUnderUserGroup(TeamNoteGeneralConstant.ROOT_USER_GROUP_ID);
    this.selectedMembers = [];
  }

  getAllChatGroups(): void {
    this.chatGroups = this._chatService.getAllGroupChats();
  }

  updateUnselectedList(keyword: string): void {
    this.searchKeyword = keyword;
    this.filteredChatGroups = this._ftsChatService.searchChat(this.searchKeyword, this.chatGroups);
  }

  selectMember(contact: UserContact): void {
    this.selectedMembers = _.union(this.selectedMembers, [contact]);
    this.updateUnselectedList(this.searchKeyword);
  }

  selectMembers(contacts: UserContact[]): void {
    this.selectedMembers = _.union(this.selectedMembers, contacts);
    this.updateUnselectedList(this.searchKeyword);
  }

  unSelectMember(contact: UserContact): void {
    this.selectedMembers = _.without(this.selectedMembers, contact);
    this.updateUnselectedList(this.searchKeyword);
  }

  unSelectAllMembers(): void {
    this.selectedMembers = [];
    this.updateUnselectedList(this.searchKeyword);
  }
  
  chatGroupOnClick(chat: Chat): void {
    _.each(chat.members, (userId) => {
      if (userId !== this._accountManagerService.userId) {
        let u = this._userContactService.getUserContactByUserId(userId);
        this.selectMember(u);
      }
    });
  }

  cancel(): void {
    this.dialogRef.close();
  }

  handleFileInputChange(e): void {
    this.file = e.target.files[0];

    let reader = new FileReader();
    reader.onload = (e: any) => {
      this.imgSrc = e.target.result;

      this._imageEditorService.openImageEditorModal(
        this.imgSrc,
        (imageDataUrl) => {
          this.imgSrc = imageDataUrl;
          this.file = this._fileManagerService.dataUrlToFile(this.imgSrc, this.file.name);
        },
        false
      );

    };
    reader.readAsDataURL(this.file);
  }

  checkInput(): boolean {
    if (this.groupName.length === 0) {
      this._tnNotificationService.showCustomWarningByTranslateKey('WEBCLIENT.CHAT.CHAT_GROUP.ERROR_MSG.NO_GROUP_NAME');
      return false;
    }
    if (this.selectedMembers.length == 0) {
      this._tnNotificationService.showCustomWarningByTranslateKey('WEBCLIENT.CHAT.CHAT_GROUP.ERROR_MSG.NO_MEMBER');
      return false;
    }

    return true;
  }

  uploadChatPic(): void {
    // upload file directly as image is edited already
    this._fileManagerService.apiUploadFile(
      this.file,
      (fileId) => {
        this.picAttachmentId = fileId;
        this.submitNewChatGroup();
      }
    );
  }

  submitNewChatGroup(): void {
    let members = _.map(this.selectedMembers, 'user_id');
    this._chatService.createChatGroup(
      this.groupName, 
      this.picAttachmentId, 
      members, 
      this.groupMessage, 
      this.chatSecurityLevel,
      () => this.cancel()
    );
  }

  create(): void {
    if (!this.checkInput()) {
      return;
    }

    const actionCallback = () => {
      if (this.file) {
        this.uploadChatPic();
      } else {
        this.submitNewChatGroup();
      }
    }

    if (this.isEnableWarnNoDeviceUserGroupChat) {
      const isSomeMemberIsInactive = this.selectedMembers.length && _.some(this.selectedMembers, (contact) => contact.device_count == 0);

      if (isSomeMemberIsInactive) {
        this._tnNotificationService.showPrompt(
          null,
          'WEBCLIENT.CONTACT.INACTIVE_USER_WARNING',
          () => {
            // cancel Prompt ballback
            // this.dialogRef.close();
          },
          () => {
            actionCallback();
          },
          false,
          true
        )
      } else {
        actionCallback();
      }
    } else {
      actionCallback();
    }

    // if (this.file) {
    //   this.uploadChatPic();
    // } else {
    //   this.submitNewChatGroup();
    // }
  }

  // broadcast


  toggleSelectBroadcastList(): void {
    this._broadcastService.getBroadcastGroupList(
      resp => {
        this.broadcastGroups = resp;
        this.selectionMode = 'broadcast';
      },
      err => {

      }
    );
  }

  selectBroadcastGroup(broadcastGroup: BroadcastGroup): void {
    this._broadcastService.getBroadcastGroupById(
      broadcastGroup.user_group_id,
      resp => {
        let group: BroadcastGroup = resp;
        let memberIds = _.map(group.user_group_members, 'user_id');
        _.each(memberIds, (id) => {
          let member = _.find(this.contactUsers, {user_id: id});
          if (member) {
            this.selectMember(member);
          }
        });
      },
      err => {

      }
    );
  }
}
