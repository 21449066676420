export const ZhChtContactStrings = {
  NAME: "名稱",
  EMAIL: "電郵",
  DEPARTMENT: "部門",
  TITLE: "職位名稱",
  PUBLIC_MESSAGE: "公開訊息",
  MOBILE: "手提電話號碼",
  E_PROFILE: "E-Profile",

  DELETED_POSTFIX: "(已刪除)",

  INACTIVE_USER_WARNING: "你所選取的用戶，尚未激活即時通訊應用程式的帳號。 該用戶啟用帳戶後，將收到所有未讀的訊息。是否繼續將他加入群組？",

  UNREGISTERED: "未激活",
  UNREGISTERED_FIELD: "此用戶未啟用",

  ME: '您'
};
