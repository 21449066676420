<div class="contact-card-wrapper">

  <div class="card-top">
    <div class="avatar-row">
      <div class="avatar-wrapper">
        <div class="avatar default-contact-avatar" [ngStyle]="{'background-image': avatarImageSrc}"></div> 
        <div class="device-icon" *ngIf="contact.device_count > 0"></div>
      </div>
    </div>

    <div class="info-wrapper">
      <div 
        class="name text-color-primary"
        [ngClass]="{
          'important-user': 'isEnableImportantUsers',
          'global-vip': userImportantLevel === IMPORTANT_LEVEL.GLOBAL,
          'individual-vip': userImportantLevel === IMPORTANT_LEVEL.INDIVIDUAL
        }"
      >
        {{contact.name}}
        <ng-container *ngIf="isMe">
          <span>({{'WEBCLIENT.CONTACT.ME' | translate}})</span>
        </ng-container>
        <ng-container *ngIf="isEnableDisplayNoDeviceUserIndicator && contact?.device_count == 0">
          <span>({{'WEBCLIENT.CONTACT.UNREGISTERED' | translate}})</span>
        </ng-container>
        <ng-container *ngIf="isEnableImportantUsers">
          <div class="global-vip-icon" *ngIf="userImportantLevel === IMPORTANT_LEVEL.GLOBAL"></div>
          <div class="individual-vip-icon" *ngIf="userImportantLevel === IMPORTANT_LEVEL.INDIVIDUAL"></div>
        </ng-container>
        <ng-container *ngIf="contact.deleted">
          {{'WEBCLIENT.CONTACT.DELETED_POSTFIX' | translate}}
        </ng-container>
      </div>
      <div class="desc text-color-secondary" *ngFor="let desc of descFields">
        <ng-container *ngIf="desc !== 'e_profile'">
          <span *ngIf="desc !== 'unregistered'">{{contact[desc]}}</span>
          <span *ngIf="desc === 'unregistered'" style="font-weight: bold; color: black;">{{'WEBCLIENT.CONTACT.UNREGISTERED_FIELD' | translate}}</span>
        </ng-container>
        <a 
          *ngIf="desc === 'e_profile' && contact.skype_id" 
          class="e-profile-btn-wrapper" 
          href="{{ contact.skype_id }}" 
          target="_blank"
        >
          <img src="assets/icons/e-profile-blue.svg" alt="E-Profile"/>
          <span>E-Profile</span>
        </a>
      </div>
    </div>
  </div>

  <div class="card-bottom options-wrapper" *ngIf="isShowActions">
    <div class="option chat" (click)="onChatClick()" *ngIf="!isEnableLimitNoDeviceUserIndividualChat || (isEnableLimitNoDeviceUserIndividualChat && contact?.device_count > 0)">
      <i class="fa fa-fw fa-2x fa-commenting-o"></i>
      {{'WEBCLIENT.CHAT.CONTACT_CARD.CHAT' | translate}}
    </div>
    <div class="option whisper" (click)="onWhisperClick()" *ngIf="isShowWhisper">
      <i class="fa fa-fw fa-2x fa-comment-o"></i>
      {{'WEBCLIENT.CHAT.CONTACT_CARD.WHISPER' | translate}}
    </div>
    <div
      class="option important-user-btn"
      (click)="onImportantUserClick()"
      *ngIf="isEnableImportantUsers && contact.importantLevel !== IMPORTANT_LEVEL.GLOBAL"
    >
      <ng-container *ngIf="userImportantLevel !== IMPORTANT_LEVEL.INDIVIDUAL">
        <i class="fa fa-fw fa-2x fa-star-o i-color"></i>
        {{'WEBCLIENT.CHAT.CONTACT_CARD.IMPORTANT_USER.SET' | translate}}
      </ng-container>
      <ng-container *ngIf="userImportantLevel === IMPORTANT_LEVEL.INDIVIDUAL">
        <i class="fa fa-fw fa-2x fa-star"></i>
        {{'WEBCLIENT.CHAT.CONTACT_CARD.IMPORTANT_USER.UNSET' | translate}}
      </ng-container>
    </div>
  </div>

</div>