import { Injectable } from '@angular/core';
import { UserContact } from '../../../models/user-contact';
import { TnDialogService } from '../../../utilities/tn-dialog/tn-dialog.service';
import { ContactCardComponent } from './contact-card.component';

@Injectable()
export class ContactCardService {

  constructor(
    private _tnDialogService: TnDialogService
  ) { }

  openContactCardModal(contact: UserContact, isShowWhisper: boolean, whisperCallback?: Function, isShowImportantUser?: boolean, isShowCheckInactiveUserPrompt?: boolean) {
    let dialogRef = this._tnDialogService.openTnDialog(ContactCardComponent, {
      contact: contact,
      isShowWhisper: isShowWhisper,
      whisperCallback: whisperCallback,
      isShowImportantUser: isShowImportantUser,
      isShowCheckInactiveUserPrompt: isShowCheckInactiveUserPrompt,
    });
  }

}
