import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { BroadcastService } from '../broadcast.service';
import { UserContact } from '../../../models/user-contact';
import { UtilitiesService } from '../../../utilities/service/utilities.service';
import { LoggerService } from '../../../utilities/logger/logger.service';
import { FileUploaderService } from '../../../utilities/file-uploader/file-uploader.service';
import { AMQPRoutingKey } from '../../../constants/amqp-routing-key.constant';
import { MessageTypeConstant } from '../../../constants/message-type.constant';
import { LocationSelectorService } from '../../../utilities/location-selector/location-selector.service';
import { TeamnoteConfigService } from '../../../configs/teamnote-config.service';
import { MessageLocationBody } from '../../../models/message-location-body';

import * as _ from 'lodash';
import { ChatRoomService } from '../../chat/chat-room/chat-room.service';
import { TnNotificationService } from '../../../utilities/tn-notification/tn-notification.service';
import { FileManagerService } from '../../../utilities/file-manager/file-manager.service';
import { WebclientService } from '../../webclient.service';

@Component({
  selector: 'tn-broadcast-send',
  templateUrl: './broadcast-send.component.html',
  styleUrls: ['./broadcast-send.component.scss']
})
export class BroadcastSendComponent implements OnInit {

  @Output() close: EventEmitter<any> = new EventEmitter<any>();

  // Two-way binding to recipient selector, just use it directly
  selectedContacts: UserContact[] = [];

  // For inputs
  isAllowEncryptedMessage: boolean = false;
  isToggledEncryptedMessage: boolean = false;
  inputMessage: string = "";

  constructor(
    private _broadcastService: BroadcastService,
    private _utilitiesService: UtilitiesService,
    private _loggerService: LoggerService,
    private _fileUploaderService: FileUploaderService,
    private _locationSelectorService: LocationSelectorService,
    private _teamnoteConfigService: TeamnoteConfigService,
    private _fileManagerService: FileManagerService,
    private _chatRoomService: ChatRoomService,
    private _tnNotificationService: TnNotificationService,
    private _webclientService: WebclientService,
  ) { }

  ngOnInit() {
    // this.isAllowEncryptedMessage = this._teamnoteConfigService.config.WEBCLIENT.CHATROOM.ENABLE_ENCRYPTED_MESSAGE;
    this.isAllowEncryptedMessage = this._webclientService.allow_encrypted_message;
  }

  openUploadFilesModal(defaultFiles: File[]): void {
    if (!this.checkIsHaveRecipients()) {
      return;
    }
    this._loggerService.log("Clicked file selection button");
    this._fileUploaderService.openFileUploaderModal(AMQPRoutingKey.SEND_MESSAGE, (files) => this.onFinishSelectFile(files), defaultFiles);
  }

  onFinishSelectFile(files: File[]): void {
    this.askForConfirmation(
      () => {
        _.each(files, (f) => {
          this.prepareAttachmentMessageBody(
            f,
            (body) => {
              this.sendMessageHub(MessageTypeConstant.ATTACHMENT, body);
              this.resetAllInputs();
            }
          );
        });
      }
    );
  }

  prepareAttachmentMessageBody(file: File, callback: Function): void {
    this._fileManagerService.apiUploadFile(
      file,
      (fileId, imageCaption) => {
        callback(this._chatRoomService.getAttachmentMessageBody(fileId, file, imageCaption, null, this.isToggledEncryptedMessage));
      },
      true
    );
  }

  openLocationSelectModal(): void {
    if (!this.checkIsHaveRecipients()) {
      return;
    }
    this._loggerService.log("Clicked location selection button");
    this._locationSelectorService.openLocationSelectorModal((location) => this.onFinishSelectLocation(location));
  }

  onFinishSelectLocation(locationBody: MessageLocationBody): void {
    this.askForConfirmation(
      () => {
        this.sendMessageHub(MessageTypeConstant.LOCATION, this._chatRoomService.getLocationMessageBody(locationBody, null, this.isToggledEncryptedMessage));
        this.resetAllInputs();
      }
    );
  }

  toggleEncryptedMessage(): void {
    this._loggerService.log("Clicked encrypted message toggle lock");
    this.isToggledEncryptedMessage = !this.isToggledEncryptedMessage;
  }

  inputKeyEnter(isWithShiftKey: boolean): boolean {
    if (this._utilitiesService.checkIfKeyEnterShouldSend(isWithShiftKey)) {
      this.send();
      return false;
    }
    return true;
  }

  send(): void {
    if (this.inputMessage.length > 0) {
      if (!this.checkIsHaveRecipients()) {
        return;
      }
      this.askForConfirmation(
        () => {
          this.sendMessageHub(MessageTypeConstant.TEXT, this._chatRoomService.getTextMessageBody(this.inputMessage, null, this.isToggledEncryptedMessage));
          this.resetAllInputs();
        }
      );
    }
  }

  resetAllInputs(): void {
    this.inputMessage = "";
    this.isToggledEncryptedMessage = false;
  }

  checkIsHaveRecipients(): boolean {
    if (this.selectedContacts.length == 0) {
      this._tnNotificationService.showCustomErrorByTranslateKey("WEBCLIENT.BROADCAST.SEND.NO_RECIPIENT_ERROR_MSG");
      return false;
    }
    return true;
  }

  askForConfirmation(success: Function): void {
    let key = "WEBCLIENT.BROADCAST.SEND.CONFIRM_MSG";
    if (this.selectedContacts.length == 1) {
      key += ".ONE";
    } else {
      key += ".MULTIPLE";
    }
    this._tnNotificationService.showConfirmByTranslateKey(
      key,
      success,
      null,
      {NUM: this.selectedContacts.length}
    );
  }

  sendMessageHub(messageType: number, content: any): void {
    let recipientIds = _.map(this.selectedContacts, 'user_id');
    this._broadcastService.sendBroadcastMessage(
      messageType,
      JSON.stringify(content),
      recipientIds,
      resp => {
        this._tnNotificationService.showCustomInfoByTranslateKey("WEBCLIENT.BROADCAST.SEND.SEND_BROADCAST_SUCCESS_MSG");
        this.close.emit();
      },
      err => {

      }
    );
  }

}
