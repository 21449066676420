import { Injectable } from '@angular/core';
import { UserContact } from '../../models/user-contact';
import { ChatService } from '../services/data/chat/chat.service';
import { Router } from '@angular/router';
import { PageUrlConstant } from '../../constants/page-url.constant';
import { SideNavService } from '../../utilities/tn-side-nav/side-nav.service';
import { ModuleKeyDefinition } from '../../constants/module.constant';

@Injectable()
export class ContactService {

  constructor(
    private _chatService: ChatService,
    private _router: Router,
    private _sideNavService: SideNavService
  ) { }

  contactCellOnClick(contact: UserContact): void {
    let chat = this._chatService.getIndividualChatByUserId(contact.user_id);
    console.log('self chat?', chat);
    if (chat) {
      this._chatService.activeChat = chat;
      this._chatService.updateActiveChatSubject(chat);
      this.goToChatRoom();
    } else {
      // declare chat first
      this._chatService.declareIndividualChatByUserId(contact.user_id, () => this.goToChatRoom());
    }
  }

  goToChatRoom(): void {
    // Update side menu
    this._sideNavService.updateActiveSideNav(ModuleKeyDefinition.CHAT);

    // go to chat room
    this._router.navigate(['../' + PageUrlConstant.WEBCLIENT.BASE + '/' + PageUrlConstant.WEBCLIENT.CHAT]);
  }

}
