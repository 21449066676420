<div class="chat-group-new-modal">
  <div class="modal-header">
    <h4 class="text-color-primary">{{'WEBCLIENT.CHAT.NEW_GROUP' | translate}}</h4>
  </div>
  <div class="modal-body">
    <div class="chat-group-new-container">
      <div class="chat-group-preview">
        <div class="group-detail">
          <div class="group-avatar-wrapper image-upload-button">
            <label class="clickable">
              <input type="file" name="file" accept="image/*" (change)="handleFileInputChange($event)"/>
              <div class="avatar default-group-avatar" [ngStyle]="{'background-image': 'url(' + imgSrc + ')'}"></div>
            </label>
          </div>
          <div class="group-info-wrapper">
            <div class="group-name-input">
              <div class="input-group">
                <input 
                  type="text" 
                  class="form-control" 
                  [placeholder]="'WEBCLIENT.CHAT.CHAT_GROUP.GROUP_NAME' | translate"
                  [(ngModel)]="groupName" 
                  [maxlength]="groupNameMax"/>
                <span class="input-group-addon">({{groupName.length}}<span *ngIf="groupNameMax">/{{groupNameMax}}</span>)</span>
              </div>
            </div>
            <div class="chat-security-option">
              <mat-form-field>
                <mat-select [(ngModel)]="chatSecurityLevel">
                  <mat-option *ngFor="let option of chatSecurityOptions" [value]="option.value">
                    {{option.labelTranslateKey | translate}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="chat-action-option" (click)="selectionMode = 'user'">
              <span>{{'WEBCLIENT.CHAT.CHAT_GROUP.ADD_MEMBER' | translate}}</span>
              <i class="fa fa-fw fa-plus"></i>
            </div>
            <div class="chat-action-option" (click)="selectionMode = 'group'">
              <span>{{'WEBCLIENT.CHAT.CHAT_GROUP.ADD_MEMBER_FROM_CHAT_GROUP' | translate}}</span>
              <i class="fa fa-fw fa-plus"></i>            
            </div>
            <div class="chat-action-option" (click)="toggleSelectBroadcastList()" *ngIf="IS_ALLOW_BROADCAST">
              <span>{{'WEBCLIENT.CHAT.CHAT_GROUP.ADD_MEMBER_FROM_BROADCAST' | translate}}</span>
              <i class="fa fa-fw fa-plus"></i>            
            </div>
            <div class="chat-action-option text-red" (click)="unSelectAllMembers()">
              <span>{{'WEBCLIENT.CHAT.CHAT_GROUP.REMOVE_ALL' | translate}} ({{selectedMembers.length}})</span>
              <i class="fa fa-fw fa-times"></i>            
            </div>
          </div>
        </div>
        <div class="member-preview">
          <tn-contact-list 
            class="tn-contact-list-wrapper"
            [contacts]="selectedMembers"
            [isShowSearchBar]="false"
            [noJumpToStart]="true"
            (contactCellOnClick)="unSelectMember($event)">
          </tn-contact-list>
        </div>
      </div>

      <tn-contact-list-container 
        class="member-picker"
        *ngIf="selectionMode === 'user'"
        [noJumpToStart]="true"
        [excludingUsers]="selectedMembers"
        [isOverrideContactCellOnClick]="true"
        [isAllowSelectAll]="true"
        [excludingSelf]="true"
        (selectAllOnClickEvent)="selectMembers($event)"
        (contactCellOnClickEvent)="selectMember($event)">
      </tn-contact-list-container>

      <div class="member-picker" *ngIf="selectionMode === 'group'">
        <tn-search-bar 
          class="tn-search-bar" 
          (onKeywordChanged)="updateUnselectedList($event)">
        </tn-search-bar>
        <div class="selection-list">
          <div *ngFor="let c of filteredChatGroups">
            <tn-chat-cell 
              [chat]="c" 
              (chatOnClick)="chatGroupOnClick($event)">
            </tn-chat-cell>
          </div>
        </div> <!--selection-list-->
      </div>

      <div class="member-picker" *ngIf="selectionMode === 'broadcast'">
        <tn-contact-list
          class="tn-contact-list-wrapper"
          [userGroups]="broadcastGroups"
          [isShowSearchBar]="true"
          [allowSearchUserGroup]="true"
          [noJumpToStart]="true"
          (userGroupCellOnClick)="selectBroadcastGroup($event)">
        </tn-contact-list>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-default" (click)="cancel()">{{'GENERAL.CANCEL' | translate}}</button>
    <button class="btn tn-button-primary" (click)="create()">{{'GENERAL.CONFIRM' | translate}}</button>
  </div>
</div>