import { CommonModule } from '@angular/common';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from "@angular/router";
// import { NgMapApiLoader } from '@ngui/map';
import { TranslateModule } from '@ngx-translate/core';
import { VirtualScrollerModule } from '@iharbeck/ngx-virtual-scroller';
import { FileUploadModule } from 'ng2-file-upload';

// Select
import { NgSelectModule } from '@ng-select/ng-select';

// Modules
import { AttachmentModule } from './attachment/attachment.module';
import { AudioPlayerModule } from './audio-player/audio-player.module';
import { CommonMaterialModule } from './common-material/common-material.module';
import { CommonNgxBootstrapModule } from './common-ngx-bootstrap/common-ngx-bootstrap.module';
import { ContextMenuModule } from './context-menu/context-menu.module';
import { EmojiModule } from './emoji/emoji.module';
import { EmptyPageModule } from './empty-page/empty-page.module';
import { FileUploaderModule } from './file-uploader/file-uploader.module';
import { FtsModule } from './fts/fts.module';
import { ImageEditorModule } from './image-editor/image-editor.module';
import { InputValidationService } from "./input-validation/input-validation.service";
import { LinkifyModule } from './linkify/linkify.module';
import { LocalStorageManagerService } from './local-storage/local-storage-manager.service';
import { LocationSelectorModule } from './location-selector/location-selector.module';
import { LocationService } from './location/location.service';
import { LoggerService } from './logger/logger.service';
import { NgMapConfigLoaderService } from './ng-map-config-loader/ng-map-config-loader.service';
import { NotificationCenterModule } from './notification-center/notification-center.module';
import { PageTitleComponent } from './page-title/page-title.component';
import { PasswordReloginModule } from './password-relogin/password-relogin.module';
import { RouteParamService } from './route-param/route-param.service';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { SearchBarModule } from './search-bar/search-bar.module';
import { SearchFilterService } from './search-filter/search-filter.service';
import { UtilitiesService } from './service/utilities.service';
import { TimestampService } from './timestamp/timestamp.service';
import { TnListComponent } from './tn-list/tn-list.component';
import { TnLoaderModule } from './tn-loader/tn-loader.module';
import { TnNotificationModule } from './tn-notification/tn-notification.module';
import { TnSideNavComponent } from './tn-side-nav/tn-side-nav.component';
import { TranslateManagerService } from './translate/translate-manager.service';
import { VideoPlayerModule } from './video-player/video-player.module';
import { WebsiteTitleService } from './website-title/website-title.service';
import { WindowFocusService } from './window-focus/window-focus.service';
import { AttachmentService } from './attachment/attachment.service';
import { AudioPlayerService } from './audio-player/audio-player.service';
import { CollapsibleListService } from './collapsible-list/collapsible-list.service';
import { ContextMenuService } from './context-menu/context-menu.service';
import { CronjobTrackerService } from './cronjob-tracker/cronjob-tracker.service';
import { EmojiService } from './emoji/emoji.service';
import { FileManagerService } from './file-manager/file-manager.service';
import { FileUploaderService } from './file-uploader/file-uploader.service';
import { IdleTimeoutService } from './idle-timeout/idle-timeout.service';
import { ImageEditorService } from './image-editor/image-editor.service';
import { ImageHelperService } from './image-helper/image-helper.service';
import { LinkifyService } from './linkify/linkify.service';
import { LocationSelectorService } from './location-selector/location-selector.service';
import { NotificationCenterService } from './notification-center/services/notification-center.service';
import { PasswordReloginService } from './password-relogin/password-relogin.service';
import { PasteImageHelperService } from './paste-image-helper/paste-image-helper.service';
import { TnDialogService } from './tn-dialog/tn-dialog.service';
import { TnLoaderService } from './tn-loader/tn-loader.service';
import { TnNotificationService } from './tn-notification/tn-notification.service';
import { VersionCheckingService } from './version-checking/version-checking.service';
import { WatermarkService } from './watermark/watermark.service';
import { FileFactoryService } from './file-factory/file-factory.service';
import { SideNavService } from './tn-side-nav/side-nav.service';
import { TnLinkHelperService } from './tn-link-helper/tn-link-helper.service';
import { DateService } from './date/date.service';
import { DateModule } from './date/date.module';
import { TnDialogModule } from './tn-dialog/tn-dialog.module';
import { SignatureModule } from './signature/signature.module';
import { SignatureService } from './signature/signature.service';
import { WatermarkModule } from './watermark/watermark.module';
import { TAndCModule } from './t-and-c/t-and-c.module';
import { TAndCService } from './t-and-c/t-and-c.service';
import { StickerComponent } from './sticker/sticker.component';
import { StickerService } from './sticker/sticker.service';


@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        TranslateModule,
        CommonMaterialModule,
        TnNotificationModule,
        AttachmentModule
    ],
    declarations: [
        PageTitleComponent,
        // SearchBarComponent,
        TnListComponent,
        TnSideNavComponent,
        StickerComponent
    ],
    exports: [
        AttachmentModule,
        AudioPlayerModule,
        CommonMaterialModule,
        CommonNgxBootstrapModule,
        ContextMenuModule,
        EmojiModule,
        EmptyPageModule,
        FileUploadModule,
        FileUploaderModule,
        FormsModule,
        ImageEditorModule,
        LocationSelectorModule,
        PageTitleComponent,
        PasswordReloginModule,
        // SearchBarComponent,
        SearchBarModule,
        TnListComponent,
        TnLoaderModule,
        TnNotificationModule,
        TnSideNavComponent,
        TranslateModule,
        VideoPlayerModule,
        VirtualScrollerModule,
        NgSelectModule,
        FtsModule,
        LinkifyModule,
        NotificationCenterModule,
        DateModule,
        TnDialogModule,
        SignatureModule,
        WatermarkModule,
        TAndCModule,
        StickerComponent
    ],
    providers: []
})
export class UtilitiesModule { 
  static forRoot(): ModuleWithProviders<UtilitiesModule> {
    return {
      ngModule: UtilitiesModule,
      providers: [
        AttachmentService,
        AudioPlayerService,
        CollapsibleListService,
        ContextMenuService,
        CronjobTrackerService,
        EmojiService,
        FileManagerService,
        FileUploaderService,
        IdleTimeoutService,
        ImageEditorService,
        ImageHelperService,
        InputValidationService,
        LinkifyService,
        LocalStorageManagerService,
        LocationService,
        LocationSelectorService,
        LoggerService,
        NgMapConfigLoaderService,
        // {
        //   provide: NgMapApiLoader,
        //   useClass: NgMapConfigLoaderService
        // },
        NotificationCenterService,
        PasswordReloginService,
        PasteImageHelperService,
        RouteParamService,
        SearchFilterService,
        UtilitiesService,
        TimestampService,
        TnDialogService,
        TnLoaderService,
        TnNotificationService,
        TranslateManagerService,
        VersionCheckingService,
        WatermarkService,
        WebsiteTitleService,
        WindowFocusService,
        FileFactoryService,
        SideNavService,
        TnLinkHelperService,
        DateService,
        SignatureService,
        TAndCService,
        StickerService
      ]
    };
  }
}
