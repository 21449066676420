/**
 * Full Text Search Service (General)
 * 
 * Created by Shan - 2018-04-26
 */
import { Injectable } from '@angular/core';
import { UtilitiesService } from '../service/utilities.service';

import * as _ from 'lodash';

@Injectable()
export class FtsService {

  constructor(
    private _utilitiesService: UtilitiesService
  ) { }

  /**
   * TeamNote FTS Filtering
   * 
   * All keywords must exist in order for record to be included
   * 
   * @param {any[]} data - original data array
   * @param {string} keyword - keyword (without and parsing)
   * @param {string[]} fields - searchable fields
   * @returns {any[]} - filtered data array
   * @memberof FtsService
   */
  tnFtsFiltering(data: any[], keyword: string, fields: string[]): any[] {
    // If all keyword char is non-keyboard char, we assume it is ZH and only do indexOf checking (no further position checking)
    let isZh = this._utilitiesService.checkIfNonKeyboardCharOnly(keyword);

    keyword = keyword.toLowerCase();
    keyword = keyword.replace(/\//g, ''); // ignore all slach '/'
    
    let filtered = [];
    let splitedKeywords = keyword.trim().split(" ");

    _.each(data, (row) => {
      // Init Matching Checking Obj, {[keyword]: [state]}
      /**
       * Init Matching Checking Obj
       * {
       *  isFound: [state],
       *  isExact: if keyword requires exact wording match (must be a complete word)
       * }
       * Only the last keyword doesn't need exact match
       */
      let checkObj = {};
      _.each(splitedKeywords, (k, index) => {
        if (!k || k.length == 0) {

        } else {
          checkObj[k.trim()] = {
            isFound: false,
            isExact: index != (splitedKeywords.length - 1)
          };
        }
      });

      // Iterate through all searchable fields
      for (let fieldIndex in fields) {
        let field = fields[fieldIndex];
        let targetField = row[field];

        // Field doesn't exist, continue to next field
        if (!targetField) {
          continue;
        } else {
          targetField = targetField.toLowerCase();
          targetField = targetField.replace(/\//g, ''); // ignore all slach '/'
        }

        // Iterate through all keywords
        for (let keyword in checkObj) {
          let startPos = 0;
          let prevIndex = 0;

          // If keyword is found already or keyword doesn't exist in the current field, skip
          while(!checkObj[keyword].isFound && prevIndex != -1) {
            // Find index of keyword starting from startPos (looping in order to find all occurence of keyword)
            prevIndex = targetField.indexOf(keyword, startPos);

            // Keyword doesn't exist in field, break and go to next keyword
            if (prevIndex == -1) {
              break;
            }

            // Keyword exists!

            // Keyword is chinese char, match!
            if (isZh) {
              checkObj[keyword].isFound = true;
              break;
            }

            // If index == 0 or prevChar is "space", keyword is at word beginning
            let prevChar = targetField[prevIndex - 1];
            let isWordBeginning = prevIndex == 0 || prevChar == " ";

            if (checkObj[keyword].isExact) {
              // If keyword need exact match, check if the keyword is a "word"

              // If next char index == field length or nextChar is "space", keyword is ending
              let nextCharIndex = prevIndex + keyword.length;
              let nextChar = targetField[nextCharIndex];
              let isWordEnding = nextCharIndex == targetField.length || nextChar == " ";

              // Check if keyword is a "word"
              if (isWordBeginning && isWordEnding) {
                checkObj[keyword].isFound = true;
                break;
              }
            } else {
              // Keyword doesn't need exact match
              // If only one keyword, it is found
              if (splitedKeywords.length == 1) {
                checkObj[keyword].isFound = true;
                break;
              }
              // If more than one keyword, just check isWordBeginning
              if (isWordBeginning) {
                checkObj[keyword].isFound = true;
                break;
              }
            }

            // Keyword is in the middle of a "word", update startPos and try to find next keyword occurence
            startPos = prevIndex + 1;
          }
        }
      }

      // Finished searching all fields, check if all keywords are found
      let isAllMatch = true;
      for (let keyword in checkObj) {
        // One of the keyword is not found, no need to check others
        if (!checkObj[keyword].isFound) {
          isAllMatch = false;
          break;
        }
      }
      // All keywords are found, add {row} to {filtered}
      if (isAllMatch) {
        filtered.push(row);
      }
    });
    return filtered;
  }

}
